//import { useEffect, useState } from 'react'
// import { getProjectId, getProjectSetting } from './lib/firebase'
import './App.css'
import ScheduleLayout from './components/ScheduleLayout'
import SchedulePage from './components/SchedulePage'
import { CancelView } from './components/Edit/CancelView'
import { getUrlParameter, checkUrlParameter } from './lib/utilities'

const appointmentId = getUrlParameter('cancelAppointment')
const lang = getUrlParameter('lang')
const isCancelView =
  checkUrlParameter('cancelAppointment') &&
  appointmentId !== null &&
  appointmentId !== undefined &&
  appointmentId !== ''

function App() {
  return (
    <ScheduleLayout>
      {isCancelView ? (
        <CancelView appointmentId={appointmentId} lang={lang} />
      ) : (
        <SchedulePage />
      )}
    </ScheduleLayout>
  )
}

export default App
