import checkoutFormModel from './scheduleFormModel'

const {
  formField: {
    time,
    brokerId,
    intervals,
    acceptances,
    firstName,
    lastName,
    tel,
    email,
    apartmentType,
    heardFrom,
    message,
    acceptTerms
  },
} = checkoutFormModel

let values = {
  [time.name]: '',
  [firstName.name]: '',
  [lastName.name]: '',
  [tel.name]: '',
  [email.name]: '',
  [apartmentType.name]: '',
  [heardFrom.name]: '',
  [message.name]: '',
  [acceptTerms.name]: false,
  brokerId: '',
  intervals: '',
  meeting_point: '',
  per_slot: '',
  description: '',
  acceptance_1: false
}

// acceptances.forEach(acceptance => {
//   values[acceptance.name] = false
// })

export default values
