import { Grid, Input } from '@mui/material'
import InputField from '../FormFields/InputField'
import TextAreaField from '../FormFields/TextAreaField'
import CheckBoxField from '../FormFields/CheckBoxField'
import RadioGroupField from '../FormFields/RadioGroupField'
import SelectField from '../FormFields/SelectField'
import PhoneField from '../FormFields/PhoneField'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import InfoIcon from '@mui/icons-material/Info'
import { useEffect, useState } from 'react'
import {
  getCustomFields,
  getProjectId,
  getProjectSettings,
} from '../../lib/firebase'
const Details = props => {
  const {
    formField: {
      firstName,
      lastName,
      tel,
      email,
      apartmentType,
      heardFrom,
      message,
      acceptTerms,
    },
    language,
  } = props
  const [customFields, setCustomFields] = useState([])
  const [settings, setSettings] = useState({})
  const projectId = getProjectId()
  const isEnglish = language === 'en'

  useEffect(() => {
    getCustomFields(projectId).then(res => {
      setCustomFields(res)
    })

    // get project settings
    getProjectSettings(projectId).then(res => {
      setSettings(res)
    })
  }, [projectId])

  const apartmentOptions = isEnglish
    ? settings?.apartment_options_en
    : settings?.apartment_options

  const acceptTermsLabel = isEnglish ? acceptTerms.labelEn : acceptTerms.label
  const acceptanceText = isEnglish
    ? settings?.acceptance_text_en
    : settings?.acceptance_text

  return (
    <>
      <Grid container spacing={5} mt={8}>
        <Grid item xs={12} sm={6}>
          <InputField
            required
            name={firstName.name}
            label={isEnglish ? firstName.labelEn : firstName.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            required
            name={lastName.name}
            label={isEnglish ? lastName.labelEn : lastName.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <PhoneField
              required
              name={tel.name}
              label={isEnglish ? tel.labelEn : tel.label}
            />
            <Tooltip
              title={
                isEnglish
                  ? 'The phone number will only be used for communication regarding the viewing appointment.'
                  : 'Die Telefonnummer wird ausschließlich für die Kommunikation bezüglich des Besichtigungstermins verwendet.'
              }
              interactive
            >
              <IconButton onClick={e => e.preventDefault()}>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </div>
          {/* <InputField type="tel" name={tel.name} label={tel.label} fullWidth /> */}
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            required
            name={email.name}
            label={isEnglish ? email.labelEn : email.label}
            fullWidth
          />
        </Grid>
        {apartmentOptions && apartmentOptions.length > 0 && (
          <Grid item xs={12} sm={6}>
            <RadioGroupField
              name={apartmentType.name}
              label={isEnglish ? apartmentType.labelEn : apartmentType.label}
              fieldOptions={apartmentOptions}
              split={false}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <SelectField
            name={heardFrom.name}
            label={isEnglish ? heardFrom.labelEn : heardFrom.label}
            language={language}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextAreaField
            name={message.name}
            label={isEnglish ? message.labelEn : message.label}
            minRows={8}
            multiline
            variant="outlined"
            fullWidth
          />
        </Grid>

        {customFields.map(field => {
          let {
            field_name,
            field_label,
            field_label_en,
            field_type,
            required,
            fieldOptions,
          } = field
          if (isEnglish) {
            field_label = field_label_en ? field_label_en : field_label
          }
          switch (field_type) {
            case 'text':
              return (
                <Grid key={field_name} item xs={12} sm={6}>
                  <InputField name={field_name} label={field_label} fullWidth />
                </Grid>
              )
            case 'textarea':
              return (
                <Grid key={field_name} item xs={12} sm={6}>
                  <TextAreaField
                    name={field_name}
                    label={field_label}
                    minRows={8}
                    multiline
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              )
            case 'radio':
              return (
                <Grid key={field_name} item xs={12} sm={6}>
                  <RadioGroupField
                    name={field_name}
                    label={field_label}
                    fieldOptions={isEnglish ? field.options_en : field.options}
                    variant="outlined"
                  />
                </Grid>
              )
            case 'select':
              return (
                <Grid key={field_name} item xs={12} sm={6}>
                  <SelectField
                    name={field_name}
                    label={field_label}
                    fieldOptions={isEnglish ? field.options_en : field.options}
                    variant="outlined"
                  />
                </Grid>
              )
            case 'checkbox':
              return (
                <Grid key={field_name} item xs={12} sm={6}>
                  <CheckBoxField
                    name={field_name}
                    label={field_label}
                    variant="outlined"
                  />
                </Grid>
              )
            case 'email':
              return (
                <Grid key={field_name} item xs={12} sm={6}>
                  <InputField name={field_name} label={field_label} fullWidth />
                </Grid>
              )

            default:
              break
          }
        })}
        <Grid item xs={12} sm={12}>
          <CheckBoxField
            required
            name={acceptTerms.name}
            label={acceptanceText || acceptTermsLabel}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default Details
