import { Paper } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import { theme, useStyle } from './styles'

const ScheduleForm = props => {
  const { children } = props
  const classes = useStyle()
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Paper className={classes.paper}>{children}</Paper>
      </div>
    </ThemeProvider>
  )
}

export default ScheduleForm
