import { useState, useEffect } from 'react'
import {
  getProjectId,
  createAppointment,
  checkIfApartment,
  checkIfUserHasAppointment,
  isTimeFullyBooked,
  allSlotsBooked,
} from '../../lib/firebase'
import { ProjectContext } from '../../contexts'
import FormSteppers from '../FormSteppers'
import { useProjectData } from '../../hooks'
import TimeSelection from '../Steps/TimeSelection'
import Acceptance from '../Steps/Acceptance'
import Details from '../Steps/Details'
import Confirmation from '../Steps/Confirmation'
import { Formik, Form } from 'formik'
import formInitialValues from '../FormModel/formInitialValues'
import validationSchema from '../FormModel/validationSchema'
import scheduleFormModal from '../FormModel/scheduleFormModel'
import SignUpForm from '../SignUpForm'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import { green } from '@mui/material/colors'
import { Send } from '@mui/icons-material'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import { useTimeSlots } from '../../hooks'
const { formId, formField } = scheduleFormModal

function scrollToBookingForm() {
  const element = document.getElementById('mmi-booking-form')
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }
}

function renderStepContent(
  step,
  isValid,
  isSubmitting,
  timeSlots,
  checkedEnglish
) {
  // step = 2
  switch (step) {
    case 0:
      return (
        <TimeSelection
          timeSlots={timeSlots}
          language={checkedEnglish ? 'en' : 'de'}
        />
      )
    case 1:
      return (
        <Acceptance
          isValid={isValid}
          isSubmitting={isSubmitting}
          language={checkedEnglish ? 'en' : 'de'}
        />
      )
    case 2:
      return (
        <Details
          formField={formField}
          language={checkedEnglish ? 'en' : 'de'}
        />
      )
    case 3:
      scrollToBookingForm()
      return <Confirmation language={checkedEnglish ? 'en' : 'de'} />
    default:
      return <div>Not Found</div>
  }
}

let steps = ['Zeit', 'Hinweise', 'Details', 'Bestätigung']

const SchedulePage = () => {
  const projectId = getProjectId()
  const projectData = useProjectData(projectId)
  const [activeStep, setActiveStep] = useState(0)
  const [slotsLoaded, setSlotsLoaded] = useState(false)
  const [checkedEnglish, setCheckedEnglish] = useState(false)
  const [schemas, setSchemas] = useState([])
  const isLastStep = activeStep === steps.length - 1
  const isSubmissionStep = activeStep === 2
  const currentValidationSchema = schemas[activeStep]
  const { timeSlots, loading } = useTimeSlots(projectId)
  const slotExists = timeSlots.length > 0
  const allBooked = allSlotsBooked(timeSlots)

  const primaryColor = projectData?.settings?.primary_color
  const submitForm = async (values, actions, checkedEnglish) => {
    //let isFullyBooked = await isTimeFullyBooked(projectId, values.time, values.per_slot);
    let isFullyBooked = await isTimeFullyBooked(
      projectId,
      values.time,
      values.per_slot
    )

    if (isFullyBooked) {
      checkedEnglish
        ? alert(
            'The appointment has already been booked in the meantime. We kindly ask you to go back to the appointment overview and select a new time.'
          )
        : alert(
            'Der Termin wurde zwischenzeitlich bereits vergeben. Bitte gehen Sie zurück zur Terminbuchung und wählen Sie einen neuen Besichtigungstermin aus.'
          )
      setActiveStep(activeStep)
      actions.setSubmitting(false)
      return
    }

    // check if user has appointment
    let hasAppointment = await checkIfUserHasAppointment(
      projectId,
      values.email,
      values.tel
    )

    const isApartment = checkIfApartment() === 'yes'

    // check if user has appointment in the last 24 hours
    if (hasAppointment && !isApartment) {
      checkedEnglish
        ? alert('You already have an appointment')
        : alert('Sie haben bereits einen Termin')
      setActiveStep(activeStep)
      actions.setSubmitting(false)
    } else {
      // create appointment
      createAppointment(projectId, values, checkedEnglish).then(results => {
        if (results) {
          actions.setSubmitting(false)
        }
      })

      setActiveStep(activeStep + 1)
    }
  }

  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true)
    if (isSubmissionStep) {
      submitForm(values, actions, checkedEnglish)
    } else {
      setActiveStep(activeStep + 1)
      actions.setTouched({})
      actions.setSubmitting(false)
    }
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1)
  }

  const handleLanguageChange = event => {
    setCheckedEnglish(event.target.checked)
  }

  useEffect(() => {
    validationSchema.then(result => {
      setSchemas(result)
    })
    setSlotsLoaded(true)
  }, [])

  if (checkedEnglish) {
    steps = ['Time', 'Notes', 'Details', 'Confirmation']
  } else {
    steps = ['Zeit', 'Hinweise', 'Details', 'Bestätigung']
  }

  if (loading) {
    return <CircularProgress />
  }
  return (
    <ProjectContext.Provider value={projectData}>
      <>
        { slotExists ? (
          <>
            {!allBooked && <>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>DE</Typography>
                <Switch
                  checked={checkedEnglish}
                  onChange={handleLanguageChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                  id="language-switch"
                />
                <Typography>EN</Typography>
              </Stack>
              <FormSteppers activeStep={activeStep} steps={steps} />
            </>}
        
        {activeStep === steps.length ? (
          <span>form is done</span>
        ) : (
          <Formik
            initialValues={formInitialValues}
            validationSchema={currentValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, isValid }) => (
              <Form id={formId}>
                {renderStepContent(
                  activeStep,
                  isValid,
                  isSubmitting,
                  timeSlots,
                  checkedEnglish
                )}
                {!allBooked && <>
                {!isLastStep && (
                  <Typography variant="body1" fontSize={16} mt={1} mb={1}>
                    {checkedEnglish
                      ? 'Fields marked with an asterisk (*) are required.'
                      : 'Felder mit einem Stern (*) sind Pflichtfelder.'}
                  </Typography>
                )}
                {!isLastStep && (
                  <Stack
                    spacing={2}
                    direction="row"
                    justifyContent="flex-end"
                    mt={4}
                  >
                    {activeStep !== 0 && (
                      <Button
                        variant="contained"
                        onClick={handleBack}
                        sx={{
                          backgroundColor: primaryColor
                            ? primaryColor
                            : '#000000',
                        }}
                      >
                        {checkedEnglish ? 'Back' : 'Zurück'}
                      </Button>
                    )}
                    <Box sx={{ m: 1, position: 'relative' }}>
                      <Button
                        variant="contained"
                        disabled={isSubmitting}
                        type="submit"
                        endIcon={isSubmissionStep && <Send />}
                        sx={{
                          backgroundColor: primaryColor
                            ? primaryColor
                            : '#000000',
                        }}
                      >
                        {checkedEnglish
                          ? isSubmissionStep
                            ? 'Complete booking'
                            : 'Next'
                          : isSubmissionStep
                          ? 'abschließen'
                          : 'Nächste'}
                      </Button>
                      {isSubmitting && (
                        <CircularProgress
                          size={24}
                          sx={{
                            color: green[500],
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                          }}
                        />
                      )}
                    </Box>
                  </Stack>
                )}
                </>
                }
              </Form>
            )}
          </Formik>
        )}
        </>
        ) : null }
        {allBooked ? (
        <>
          <SignUpForm
            colors={primaryColor ? { primaryColor } : { primaryColor: '#000000' }}
          />
        </>
        ) : null}
      </>
    </ProjectContext.Provider>
  )
}

export default SchedulePage
