import { useField } from 'formik'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core'

const CheckBoxField = props => {
  const { name, label, ...rest } = props
  const [field, meta, helper] = useField(props)
  const { setValue } = helper
  // check if name starts with acceptance
  const isAcceptance = name.startsWith('acceptance')
  function renderHelperText() {
    if (meta.error && meta.touched) {
      return <FormHelperText error={true}>{meta.error}</FormHelperText>
    }
  }

  function onChange(e) {
    setValue(e.target.checked)
  }

  return (
    <FormControl {...rest}>
      <FormControlLabel
        checked={field.value || false}
        control={<Checkbox {...field} onChange={onChange} />}
        label={label}
        value={field.value || false}
      />
      { !isAcceptance && renderHelperText()}
    </FormControl>
  )
}

export default CheckBoxField
