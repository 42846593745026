import * as Yup from 'yup'
import checkoutFormModel from './scheduleFormModel'
import {
  getProjectId,
  getProjectSettings,
} from '../../lib/firebase'
const projectId = getProjectId()
const {
  formField: {
    time,
    acceptance_1,
    firstName,
    lastName,
    tel,
    email,
    apartmentType,
    heardFrom,
    acceptTerms,
  },
} = checkoutFormModel

const detailsShape = {
  [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
  [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
  [tel.name]: Yup.string().min(8).required(`${tel.requiredErrorMsg}`),

  // [tel.name]: Yup.object().shape({
  //   phone: Yup.string().min(8),
  // }),
  [email.name]: Yup.string()
    .email('Must be a valid email address')
    .required(`${email.requiredErrorMsg}`),
  [acceptTerms.name]: Yup.boolean()
    .default(false)
    .isTrue('Dieses Feld ist erforderlich')
    .required([acceptTerms.name].requiredErrorMsg),
}


const timeSchema = Yup.object().shape({
  [time.name]: Yup.string().required(`${time.requiredErrorMsg}`),
})

// acceptance_1: Yup.boolean()
// .oneOf([true], 'Must accept all conditions')
// .required('This field is required'),

const acceptanceSchema = Yup.object().shape({
  [acceptance_1.name]: Yup.boolean()
    .oneOf([true], 'Must accept all conditions')
    .required('This field is required'),
})

const projectSettingsPromise = getProjectSettings(projectId).then(settings => {
  if (settings?.apartment_options && settings?.apartment_options.length > 0) {
    // concat to the existing schema
    detailsShape[apartmentType.name] = Yup.string().required(
      `${apartmentType.requiredErrorMsg}`
    )
  }

  if (settings?.heard_from_mandatory === true) {
    detailsShape[heardFrom.name] = Yup.string().required(
      `${heardFrom.requiredErrorMsg}`
    )
  }
})

const schemas = Promise.all([projectSettingsPromise]).then(
  () => {
    const detailsSchema = Yup.object().shape(detailsShape)
    
    return [timeSchema, acceptanceSchema, detailsSchema]
  }
)

export default schemas
