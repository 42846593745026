import { useField, Field } from 'formik'
import { useState } from 'react'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import FormControlLabel from '@mui/material/FormControlLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import Alert from '@mui/material/Alert'

const splitLines = str => str.split('\n')
const SelectField = props => {
  const { errorText, name, label, fieldOptions, language, ...rest } = props
  const [field, meta, helpers] = useField(props)
  const [foundOn, setFoundOn] = useState('')

  let options = [
    'ImmobilienScout24',
    'ImmoWelt',
    'Sonstiges Immobilienportal',
    'Bekannte',
    'Werbung am Objekt',
    'Online Suchmaschine',
    'Plakatwerbung',
    'Sonstiges',
  ]

  let optionsEn = [
    'ImmobilienScout24',
    'ImmoWelt',
    'Other real estate portal',
    'Acquaintances',
    'Advertising at the property',
    'Online search engine',
    'Poster advertising',
    'Other',
  ]

  if (fieldOptions) {
    options = splitLines(fieldOptions)
  }

  const handleHeardFrom = event => {
    helpers.setValue(event.target.value)
    setFoundOn(event.target.value)
  }

  const renderHelperText = () => {
    if (meta.error && meta.touched) {
      return <FormHelperText sx={{ color: 'red' }}>{meta.error}</FormHelperText>
    }
  }
  return (
    <FormControl fullWidth>
      <InputLabel id={name}>{label}</InputLabel>
      <Select
        labelId={name}
        id={name}
        value={foundOn}
        label={label}
        name={name}
        onChange={handleHeardFrom}
      >
      {/* <Field name={name} label={label} component={Select}> */}
        {options.map(option => (
          <MenuItem value={option} key={option}>
            {language === 'de' ? option : optionsEn[options.indexOf(option)]}
          </MenuItem>
        ))}
      {/* </Field> */}
      </Select>
      {renderHelperText()}
    </FormControl>
  )
}

export default SelectField
