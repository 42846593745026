import Stack from '@mui/material/Stack'
import TimeList from './TimeList'
import { TextField } from '@mui/material'
import { useField, useFormikContext } from 'formik'

const TimeSelection = ({timeSlots, language}) => {
  const brokerField = useField('brokerId')
  const intervalField = useField('intervals')
  const meetingPointField = useField('meeting_point')
  const perSlot = useField('per_slot')
  const { setFieldValue } = useFormikContext()
  const assingBroker = value => {
    setFieldValue('brokerId', value)
  }
  const assingIntervals = value => {
    setFieldValue('intervals', value)
  }

  const assingMeetingPoint = value => {
    setFieldValue('meeting_point', value)
  }

  const assingPerSlot = value => {
    setFieldValue('per_slot', value)
  }

  const assingSlotDescription = value => {
    setFieldValue('description', value)
  }

  return (
    <>
      <Stack mt={8} justifyContent="center" spacing={2}>
        {/* check if there is timeslots */}
        {(timeSlots.length > 0 && (
          <TimeList
            timeSlots={timeSlots}
            assingBroker={assingBroker}
            assingIntervals={assingIntervals}
            assingMeetingPoint={assingMeetingPoint}
            assingPerSlot={assingPerSlot}
            assingSlotDescription={assingSlotDescription}
            language={language}
          />
        ))}

        <TextField {...brokerField[0]} sx={{ display: 'none' }} />
        <TextField {...intervalField[0]} sx={{ display: 'none' }} />
        <TextField {...meetingPointField[0]} sx={{ display: 'none' }} />
        <TextField {...perSlot[0]} sx={{ display: 'none' }} />
      </Stack>
    </>
  )
}

export default TimeSelection
