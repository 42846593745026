import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, TextField, Grid, Container, Typography, CircularProgress, Alert } from '@mui/material';
import { Send } from '@mui/icons-material';
import { addNotification, getProjectId, getApartmentId } from '../../lib/firebase';

const NotificationEmailSignUp = ({ colors }) => {
  const [projectId, setProject] = useState(null);
  const [apartmentId, setApartmentId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const { primaryColor } = colors;

  useEffect(() => {
    setProject(getProjectId());
    setApartmentId(getApartmentId());
  }, []);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Ungültige E-Mail-Adresse').required('E-Mail ist erforderlich'),
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        await addNotification(projectId, values.email, apartmentId);
        setSuccess(true);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <div style={{ marginTop: '30px' }}>
      <form onSubmit={formik.handleSubmit}>
        <Container maxWidth="sm">
          <Typography variant="h6" component="h3" mb={3}>
          Vielen Dank für Ihr Interesse an einem Besichtigungstermin. Aufgrund der hohen Nachfrage sind derzeit alle Termine bereits vergeben.
          </Typography>
          <Typography variant="body1" component="p" mb={1}>
          Bitte geben Sie Ihre E-Mail-Adresse ein, um benachrichtigt zu werden, sobald wieder neue Termine verfügbar sind.
          </Typography>

          {error && <Alert severity="error">{error}</Alert>}
          {success && <Alert severity="success" mb={4}>Sie wurden erfolgreich zur Benachrichtigungsliste hinzugefügt.</Alert>}

          <Grid container spacing={2} mt={2}>
            <Grid item xs={9}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="E-Mail"
                placeholder="Geben Sie Ihre E-Mail ein"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                fullWidth
                type="submit"
                endIcon={loading ? <CircularProgress size={24} /> : <Send />}
                sx={{
                  backgroundColor: primaryColor ? primaryColor : '#000000',
                }}
                disabled={loading}
              >
                Senden
              </Button>
            </Grid>
          </Grid>
        </Container>
      </form>
    </div>
  );
};

export default NotificationEmailSignUp;
