import { useState } from 'react'
import { useField } from 'formik'
import MuiPhoneInput from 'material-ui-phone-number'
import { FormHelperText, TextField, styled } from '@mui/material'
const PhoneWrapper = styled('div')({
  '& svg': {
    width: '22px',
  },
})
const PhoneField = props => {
  const { errorText, ...rest } = props
  const [field, meta, helpers] = useField(props.name)
  // const styles = theme => ({
  //   root: {
  //     '& svg': {
  //       width: '22px',
  //     },
  //   },
  // })

  const handleOnChange = value => {
    helpers.setValue(value)
  }
  return (
    <PhoneWrapper>
      <MuiPhoneInput
        value={field.value}
        defaultCountry="de"
        autoFormat={false}
        inputClass="phone-selector"
        error={meta.touched && meta.error && true}
        onChange={handleOnChange}
        {...rest}
      />
    </PhoneWrapper>
  )
}

export default PhoneField
