import { useField } from 'formik'
import { TextField } from '@material-ui/core'

const InputField = props => {
  const { errorText, type = 'text', ...rest } = props
  const [field, meta] = useField(props)
  return (
    <TextField
      {...field}
      type={type}
      error={meta.touched && meta.error && true}
      value={field.value || ''}
      {...rest}
    />
  )
}

export default InputField
