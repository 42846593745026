import { useState, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import { Typography, Box, TextField, Grid, Button, FormControlLabel, Checkbox, Stack, CircularProgress } from '@mui/material';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import { cancelAppointments, getProjectId, fetchAppointmentsByEmail, formatAppointmentDate, checkAppointmentExists } from '../../lib/firebase';

const getCancelAppointmentFromURL = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('cancelAppointment');
};

export const CancelView = ({ lang }) => {
  const [loading, setLoading] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);
  const [email, setEmail] = useState('');
  const [appointments, setAppointments] = useState([]);
  const [selectedAppointments, setSelectedAppointments] = useState([]);
  const [cancelError, setCancelError] = useState(null);
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [cancelAppointment, setCancelAppointment] = useState(getCancelAppointmentFromURL());
  const [appointmentExists, setAppointmentExists] = useState(false);
  const [checkingAppointment, setCheckingAppointment] = useState(true);
  const projectId = getProjectId();

  const handleEmailChange = (e) => setEmail(e.target.value);

  const handleEmailSubmit = () => setEmailSubmitted(true);

  const handleCheckboxChange = (event, appointmentId) => {
    setSelectedAppointments(prev => event.target.checked
      ? [...prev, appointmentId]
      : prev.filter(id => id !== appointmentId));
  };

  const handleClick = () => {
    setLoading(true);
    cancelAppointments(projectId, selectedAppointments)
      .then(result => {
        setLoading(false);
        setIsCanceled(true);
        console.log('Successfully cancelled appointments');
      })
      .catch(error => {
        setLoading(false);
        console.error('Failed to cancel appointments:', error);
        setCancelError(`Failed to cancel appointments with the following IDs: ${selectedAppointments.join(", ")}`);
      });
  };
  

  useEffect(() => {
    if (cancelAppointment) {
      checkAppointmentExists(projectId, cancelAppointment)
        .then(
          exists => {
            // if exists, set appointmentExists to true and set selectedAppointments to [cancelAppointment]
            // if not exists, set appointmentExists to false and set selectedAppointments to []
            setAppointmentExists(exists);
            setSelectedAppointments(exists ? [cancelAppointment] : []);
            setCheckingAppointment(false);
          });
    }
  }, [projectId, cancelAppointment]);

  useEffect(() => {
    if (emailSubmitted) {
      fetchAppointmentsByEmail(projectId, email).then(setAppointments);
    }
  }, [email, emailSubmitted, projectId]);

  if (checkingAppointment) {
    return <CircularProgress />;
  }

  return (
    <Box>
      {appointmentExists ? (
        <>
          <Typography variant="body1" component="p" mb={4}>
            {lang !== 'en' ? 'Möchten Sie den ausgewählten Termin wirklich stornieren?' : 'Are you sure you want to cancel the selected appointment?'}
          </Typography>
          <LoadingButton
            size="small"
            onClick={handleClick}
            endIcon={<CancelScheduleSendIcon />}
            loading={loading}
            loadingPosition="end"
            variant="contained"
          >
            {lang !== 'en' ? 'Termin stornieren' : 'Cancel Appointment'}
          </LoadingButton>
          {isCanceled && (
            <Typography variant="body1" component="p" color="green" mb={4}>
              {lang !== 'en' ? 'Termin erfolgreich storniert.' : 'Appointment cancelled successfully.'}
            </Typography>
          )}
        </>
      ) : (
        <>
          <Typography variant="body1" component="p" mb={4}>
            {lang !== 'en' ? 'Bitte geben Sie die E-Mail-Adresse ein, die Sie bei der Buchung verwendet haben.' : 'Please enter the email address you used when making your booking.'}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <TextField value={email} onChange={handleEmailChange} placeholder='E-Mail' fullWidth />
            </Grid>
            <Grid item xs={4}>
              <Button onClick={handleEmailSubmit} variant="contained" size="large" disabled={!email}>
                {lang !== 'en' ? 'Absenden' : 'Submit'}
              </Button>
            </Grid>
          </Grid>
          {emailSubmitted && appointments.length > 0 && (
            <>
              <Stack spacing={1} mb={4}>
                {appointments.map(appointment => (
                  <Box key={appointment.id}>
                    <FormControlLabel
                      control={
                        <Checkbox checked={selectedAppointments.includes(appointment.id)} onChange={(event) => handleCheckboxChange(event, appointment.id)} />
                      }
                      label={formatAppointmentDate(appointment)}
                    />
                  </Box>
                ))}
              </Stack>
              <LoadingButton
                size="small"
                onClick={handleClick}
                endIcon={<CancelScheduleSendIcon />}
                loading={loading}
                loadingPosition="end"
                variant="contained"
                disabled={selectedAppointments.length === 0}
              >
                {lang !== 'en' ? 'Termin stornieren' : 'Cancel Appointment'}
              </LoadingButton>
              {isCanceled && (
                <Typography variant="body1" component="p" color="green" mb={4}>
                  {lang !== 'en' ? 'Termin erfolgreich storniert.' : 'Appointment cancelled successfully.'}
                </Typography>
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
};
