import { format } from 'date-fns'
// get url parameter
export const getUrlParameter = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

// check if url parameter exists
export const checkUrlParameter = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return false;
  if (!results[2]) return true;
  return true;
}

// create a function that reorders the array according to a date property called slot, in gmt format
export const reorderArrayByDateProperty = (array, property) => {
  return array.sort((a, b) => {
    return new Date(a[property]) - new Date(b[property])
  })
}

export const groupBySlot = (arr) => {
  const result = {};
  arr.forEach((item) => {
    const timeString = format(item.slot, 'HH:mm')
      if (!result[timeString]) {
          result[timeString] = [];
      }
      result[timeString].push(item);
  });
  return result;
};

export const isProduction = () => {
  return window.location.hostname === 'termine.mueller-merkle.de';
};

