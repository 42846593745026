import { useState } from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListSubheader from '@mui/material/ListSubheader'
import FormControl from '@mui/material/FormControl'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Radio, Alert, Box } from '@mui/material'
import { format, parse } from 'date-fns'
import { de } from 'date-fns/locale'
import { useField } from 'formik'
import { utcToZonedTime } from 'date-fns-tz'
import { groupBySlot } from '../../lib/utilities'

const berlinTimeZone = 'Europe/Berlin'

const TimeList = ({
  timeSlots,
  assingBroker,
  assingIntervals,
  assingMeetingPoint,
  assingSlotDescription,
  assingPerSlot,
  language,
}) => {
  const [field, meta] = useField('time')
  const [activeDate, setActiveDate] = useState(null)
  const handleChange = (
    day,
    slot,
    brokerId,
    intervals,
    meetingPoint,
    description,
    per_slot,
    event,
    brokers
  ) => {
    setActiveDate(day)
    assingBroker(brokerId)
    assingIntervals(intervals)
    assingMeetingPoint(meetingPoint)
    assingSlotDescription(description)
    assingPerSlot(per_slot * brokers)
  }

  return (
    <>
      <FormControl>
        <RadioGroup {...field} sx={{ display: 'block' }}>
          <List
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              backgroundColor: '#ededed',
              padding: '15px',
              borderRadius: '15px',
              // overflow auto for medium screens horizontally scrollable
              overflowX: 'auto',
              // overflow hidden for small screens
              overflowY: 'hidden',
              '& ul': { padding: '0 0 20px 0' },
            }}
            subheader={<li />}
          >
            {timeSlots.map(({ day, allDaySlots }) => {
              // allDaySlots loadash groupBy slot property
              const groupedSlots = groupBySlot(allDaySlots)
              let parsedDate = parse(day, 'EEE, MMMM dd', new Date(), {
                locale: de,
              })

              // Format the date using the English locale
              let englishDateString = format(parsedDate, 'EEE, MMM dd')
              const isActive = day === activeDate

              // check if english
              if (language === 'en') {
                day = englishDateString
              }
              return (
                <ListItem
                  key={day}
                  sx={{
                    ':last-child ul': { marginBottom: 0 },
                    flex: 0,
                  }}
                >
                  <List
                    sx={{
                      display: 'block',
                      width: '100%',
                      //maxWidth: 360,
                      //bgcolor: 'background.paper',
                      position: 'relative',
                      marginBottom: '30px',
                      borderRadius: '15px',
                      border: '1px solid #c9c9c9',
                    }}
                  >
                    <ListSubheader
                      sx={{
                        left: 0,
                        width: '130px',
                        flex: '0 0 130px',
                        backgroundColor: isActive ? '#00882b' : '#000',
                        color: '#fff',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: 16,
                        fontWeight: '700',
                        lineHeight: '1',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px',
                        marginBottom: '20px',
                      }}
                    >
                      {day}
                    </ListSubheader>
                    {Object.keys(groupedSlots)
                      .sort((a, b) => a.localeCompare(b))
                      .map(key => {
                        const daySlots = groupedSlots[key]
                        const moreThanOneSlot = daySlots.length > 1

                        return (
                          <ListItem
                            key={key}
                            // add after and before in shape of x
                          >
                            {daySlots.map((daySlot, index) => {
                              const isBooked = daySlot.booked

                              const {
                                slot,
                                brokerId,
                                intervals,
                                meeting_point,
                                description,
                                per_slot,
                              } = daySlot
                              // Convert to Berlin timezone
                              const berlinTime = utcToZonedTime(
                                slot,
                                berlinTimeZone
                              )
                              const timeString = format(berlinTime, 'HH:mm')
                              // remove the booked slots
                              // if more than one slot is booked, just remove the first one
                              if (isBooked && moreThanOneSlot && index === 0) {
                                return null
                              }

                              return (
                                <Box
                                  key={timeString + '_' + brokerId}
                                  sx={{
                                    position: 'relative',
                                    '&:not(:first-of-type)': {
                                      display: 'none',
                                      position: 'relative',
                                    },
                                  }}
                                >
                                  <FormControlLabel
                                    sx={{
                                      margin: '0',
                                      padding: '7px 15px 7px 7px',
                                      justifyContent: 'center',
                                      borderRadius: '3px',
                                      boxShadow:
                                        '1px 2px 5px rgba(0, 0, 0, 0.2)',
                                      backgroundColor: isBooked
                                        ? '#ededed'
                                        : '#fff',
                                      '.MuiFormControlLabel-label': {
                                        position: 'relative',
                                      },
                                      '.MuiFormControlLabel-label::after': {
                                        content: '""',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '0',
                                        width: '100%',
                                        height: '2px',
                                        backgroundColor: '#9b9b9b',
                                        zIndex: 1,
                                        display: isBooked ? 'block' : 'none',
                                      },
                                    }}
                                    value={
                                      format(berlinTime, "yyyy-MM-dd'T'HH:mm") +
                                      '_' +
                                      brokerId
                                    }
                                    control={
                                      <Radio
                                        sx={{
                                          '&.Mui-checked': {
                                            color: '#00882b',
                                          },
                                          paddingTop: '0',
                                          paddingBottom: '0',
                                          paddingLeft: '0',
                                        }}
                                        disabled={isBooked}
                                      />
                                    }
                                    label={timeString}
                                    onChange={event =>
                                      handleChange(
                                        day,
                                        slot,
                                        brokerId,
                                        intervals,
                                        meeting_point,
                                        description,
                                        per_slot,
                                        event,
                                        daySlots.length
                                      )
                                    }
                                  />
                                </Box>
                              )
                            })}
                          </ListItem>
                        )
                      })}
                  </List>
                </ListItem>
              )
            })}
          </List>
        </RadioGroup>
      </FormControl>
      {meta.error && meta.touched && (
        <Box display={'inline-block'}>
          <Alert sx={{ width: 'auto' }} severity="error">
            {meta.error}
          </Alert>
        </Box>
      )}
    </>
  )
}

export default TimeList