let formModel = {
  formId: 'scheduleForm',
  formField: {
    time: {
      name: 'time',
      label: '',
      requiredErrorMsg: 'Selecting a time is required',
    },
    acceptance_1: {
      name: 'acceptance_1',
      label: 'Die vorstehenden Informationen wurden zur Kenntnis genommen',
      labelEn: 'The above information has been noted.',
      requiredErrorMsg: 'Dieses Feld ist erforderlich',
      requiredErrorMsgEn: 'Please fill this field',
    },
    firstName: {
      name: 'firstName',
      label: 'Vorname',
      labelEn: 'First Name',
      requiredErrorMsg: 'Please tell us your name',
    },
    lastName: {
      name: 'lastName',
      label: 'Nachname',
      labelEn: 'Last Name',
      requiredErrorMsg: 'Please tell us your surname',
    },
    tel: {
      name: 'tel',
      label: 'Telefone',
      labelEn: 'Telephone',
      requiredErrorMsg: 'Please tell us your phone',
    },
    email: {
      name: 'email',
      label: 'E-mail',
      labelEn: 'E-mail',
      requiredErrorMsg: 'Please tell us your email',
    },
    apartmentType: {
      name: 'apartmentType',
      label: 'Ich bin interessiert an folgender Wohnung',
      labelEn: 'I am interested in the following apartment',
      requiredErrorMsg: 'Dieses Feld ist erforderlich',
      requiredErrorMsgEn: 'Please fill this field',
    },
    heardFrom: {
      name: 'heardFrom',
      label: 'Wo haben Sie von uns gehört',
      labelEn: 'Where did you hear about us',
      requiredErrorMsg: 'Dieses Feld ist erforderlich',
      requiredErrorMsgEn: 'Please fill this field',
    },
    message: {
      name: 'message',
      label: 'Nachricht (optional)',
      labelEn: 'Message (optional)',
      requiredErrorMsg: '',
    },
    acceptTerms: {
      name: 'acceptTerms',
      label:
        'Ich bin mit der Datenspeicherung und Nutzung der Daten zum Zwecke der Kontaktaufnahme einverstanden. Diese Einwilligung können Sie jederzeit widerrufen per Mail an info@mueller-merkle.de. Details ergeben sich aus der Datenschutzerklärung. *',
      labelEn:
        'I agree to the data storage and use of the data for the purpose of establishing contact. You can revoke this consent at any time by sending an email to info@mueller-merkle.de. Details can be found in the data protection declaration. *',
      errorLabel: 'Bitte akzeptieren Sie die Datenschutzbestimmungen',
      errorLabelEn: 'Please accept the terms and conditions',
      requiredErrorMsg: 'Dieses Feld ist erforderlich',
      requiredErrorMsgEn: 'Please fill this field',
    },
  },
}

export default formModel
