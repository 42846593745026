import { getApp, getApps, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
//import { isProduction } from './utilities';

const firebaseConfig = {
  apiKey: "AIzaSyAR01c5VzCQDaCxvPgIohLlsPLtM8x65fA",
  authDomain: "mmi-app-d8b60.firebaseapp.com",
  projectId: "mmi-app-d8b60",
  storageBucket: "mmi-app-d8b60.appspot.com",
  messagingSenderId: "459137724932",
  appId: "1:459137724932:web:1df483c78a5895d8b5bb51"
};

// const firebaseConfig = {
//   apiKey: "AIzaSyCpOhjGhBYzsFI7LkipP6SbAzhNdPj9Ly4",
//   authDomain: "mmi-app-staging.firebaseapp.com",
//   projectId: "mmi-app-staging",
//   storageBucket: "mmi-app-staging.appspot.com",
//   messagingSenderId: "361666784757",
//   appId: "1:361666784757:web:af71b0cc7e31fe513604bc"
// };

// if (!isProduction()) {
//   firebaseConfig = {
//     apiKey: "AIzaSyCpOhjGhBYzsFI7LkipP6SbAzhNdPj9Ly4",
//     authDomain: "mmi-app-staging.firebaseapp.com",
//     projectId: "mmi-app-staging",
//     storageBucket: "mmi-app-staging.appspot.com",
//     messagingSenderId: "361666784757",
//     appId: "1:361666784757:web:af71b0cc7e31fe513604bc"
//   };
// }

export const app =
  getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();
//export const auth = getAuth(app);
// EMULATORS
export const auth = getAuth();
export const db = getFirestore(app);
