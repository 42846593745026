import { Typography } from '@mui/material'
const Confirmation = ({ language }) => {
  return (
    <>
      {language === 'en' ? (
        <>
          <Typography variant="h6" m={8} textAlign="center">
            Thank you for your booking
          </Typography>
          <Typography variant="subtitle1" m={8} textAlign="center">
            You will receive a booking confirmation by email (please also check
            the spam folder).
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="h6" m={2} textAlign="center">
            Vielen Dank für Ihre Buchung.
          </Typography>
          <Typography variant="subtitle1" m={2} textAlign="center">
            Sie erhalten in Kürze eine Buchungsbestätigung per E-Mail (bitte
            auch Spamordner prüfen).
          </Typography>
        </>
      )}
    </>
  )
}

export default Confirmation
