import CheckBoxField from '../FormFields/CheckBoxField'
import { Alert, Typography, Stack, Box, List, ListItem, ListItemText } from '@mui/material'
import { acceptances } from '../FormModel/scheduleFormModel'
import { getExtraAcceptances, getProjectId } from '../../lib/firebase'
import { useEffect, useState } from 'react'
const Acceptance = ({ isValid, isSubmitting, language }) => {
  const projectId = getProjectId()

  const [allAcceptances, setAllAcceptances] = useState([])

  useEffect(() => {
    let defaultRequirements = [
      { labelEn: 'For renting, there should be permanent employment or a permanent employment-like relationship (e.g., self-employment, retirement/pension, privateer, etc.)', label: 'Für die Anmietung sollte eine Festanstellung oder ein festanstellungsähnliches Verhältnis (bspw. Selbständigkeit, Rente/Pension, Privatier, etc.) bestehen' },
      { labelEn: 'The regular monthly net household income of the tenants should be at least 2.5 times the monthly warm rent', label: 'Das geregelte monatliche Haushaltsnettoeinkommen der Mieter sollte mindestens dem 2,5-fachen der monatlichen Warmmiete entsprechen' },
      { labelEn: 'There should be no negative Schufa entries', label: 'Es sollten keine negativen Schufa-Einträge vorliegen' }
    ]
    getExtraAcceptances(projectId).then(result => {
      setAllAcceptances([...defaultRequirements, ...result])
    })
  }, [projectId, allAcceptances])

  return (
    <>
      <Typography variant="body1" fontSize={20} mt={8} mb={2}>
        { language === 'en' ? 'Please confirm the following rental requirements:' : 'Bitte bestätigen Sie die nachfolgenden Anmietungsvoraussetzungen:' }
      </Typography>
        <Stack><ul style={{margin: 0, padding:'0 0 0 20px'}}>
        {allAcceptances.map((acceptance, index) => (
            <li style={{marginBottom:'10px', fontSize: '16px'}} key={index}>{language === 'en' ? acceptance.labelEn : acceptance.label}</li>
        ))}
        </ul></Stack>
      <Stack>
          <CheckBoxField
            required
            name='acceptance_1'
            label={language === 'en' ? 'The above information has been noted *' : 'Die vorstehenden Informationen wurden zur Kenntnis genommen *'}
          />
      </Stack>
      {!isValid && (
        <Box mt={4} display={'inline-block'}>
          <Alert sx={{ width: 'auto' }} severity="error">
            { language === 'en' ? 'Please accept all conditions' : 'Bitte akzeptieren Sie alle Bedingungen' }
          </Alert>
        </Box>
      )}
    </>
  )
}

export default Acceptance