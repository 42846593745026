import { useField } from 'formik'
import { TextField } from '@mui/material'

const TextAreaField = props => {
  const { errorText, ...rest } = props
  const [field, meta] = useField(props)
  return (
    <TextField
     {...field}
     {...rest}
    />
  )
}

export default TextAreaField
