import { useField } from 'formik'
import {
  Radio,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  FormHelperText,
} from '@mui/material'

// split string into array from new lines
const splitLines = str => str.split('\n')

const RadioField = props => {
  const { errorText, label, fieldOptions, split = true, ...rest } = props
  const [field, meta] = useField(props)
  let options = ['2 Zimmer', '3 Zimmer', '4 Zimmer']

  // reassing value to options if fieldOptions is set with splitLines
  if (fieldOptions) {
    if (split) {
      options = splitLines(fieldOptions)
    } else {
      options = fieldOptions
    }
  }
  const renderHelperText = () => {
    if (meta.error && meta.touched) {
      return <FormHelperText sx={{ color: 'red' }}>{meta.error}</FormHelperText>
    }
  }
  return (
    <FormControl>
      <FormLabel id="radio-buttons-apartment-type">{label}</FormLabel>
      <RadioGroup
        {...field}
        {...rest}
        value={field.value || ''}
        aria-labelledby="radio-buttons-apartment-type"
      >
        {options.map((option, index) => (
          <FormControlLabel
            value={option}
            control={<Radio />}
            label={option}
            key={option + index}
          />
        ))}
      </RadioGroup>
      {renderHelperText()}
    </FormControl>
  )
}

export default RadioField
